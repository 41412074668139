import * as React from "react"

import Seo from "../components/seo"
import styled from "@emotion/styled";

const Container = styled.div`
    text-align: center;
`;

const Location = styled.h2`
`

const NotFoundPage = ({ location }) => {

  return (
    <Container>
      <Seo title="404: Not Found" />
        <Location>{location.pathname}</Location>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  )
}

export default NotFoundPage

